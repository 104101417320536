import classes from './Modal.module.css'
import Button from './Roundbutton.js'
function Modal(props){


    return(
        <div>
            <div className="flex gap-4 items-center" onClick={props.close}></div>
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                    <div className='bg-white p-6 rounded-sm shadow-lg max-h-full overflow-auto w-full md:w-auto'>
                        <div className={classes.header}>
                            {/* Title */}
                            <h1 className='py-2 text-4xl font-semibold bg-blue-700 text-white text-5xl '>{props.title}</h1>
                          

                            {/* Clean close button */}
                            <button 
                            onClick={props.close} 
                            className="bg-white absolute top-0 right-0 mt-4 mr-4 text-gray-700 hover:text-gray-900 transition-colors duration-200"
                            >
                                <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
    
                        <div className="flex flex-col md:flex-row items-center justify-center gap-4 p-4">
                            {/*Show Image if it exists*/}
                            {props.imgSrc && 
                                <div className='flex items-center justify-center w-full md:w-1/3'>
                                    <img src={props.imgSrc} alt={props.title} className=' w-max-[60px] rounded-lg shadow-lg' />
                                </div>
                            }

                            <div className="w-full md:w-2/3 text-lg text-black bg-gray-200 py-4 shadow-lg rounded-lg p-2">
                                <p className='mt-2 text-gray-600'>{props.children}</p>
                            </div>
                        </div> 
                            <div className="flex items-center justify-center">
                                {props.link && <Button onClick={props.buttonClick}>Visit Website</Button>}
                            </div>
                        

                    </div>
                </div>
        </div>
        )

}

export default Modal;