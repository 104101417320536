import Card from './components/ui/card.js';
import RoundButton from './components/ui/Roundbutton.js';
import './App.css';

import React, { useState } from 'react';

import Item from './components/Item.js';
import {projects, webProjects, myStory} from './content.js'

function App() {

  const [projectItems] = useState(projects);
  const [inProcessProjects] = useState(webProjects)

    const theItems = projectItems;
    const projectsToRender = theItems.map((item, index)=>
        <Item title={item.title} 
        description={item.description} 
        type={item.type}
        modalContent={item.modalContent} 
        link={item.link} 
        target={item.target} 
        key={item.title}
        imgSrc={item.imgSrc}
        tags={item.tags} />
    );


    const theInProcessProjects = inProcessProjects;
    const theInProcessProjectsRender = theInProcessProjects.map((item, index)=>
      <Item title={item.title} 
      description={item.description} 
      type={item.type}
      modalContent={item.modalContent} 
      link={item.link} 
      target={item.target} 
      key={item.title}
      imgSrc={item.imgSrc}
      tags={item.tags} />
    )


  return (
    <div className="App">
        <header>
          <nav></nav>
        </header>

        <div className="">
        <section>
          <Card>
          <h1 className="bg-blue-700 text-white text-5xl py-4"> My Story</h1>
          <div className='width-full bg-white px-2 md:px-10 py-2'>
            <div className="flex flex-col md:flex-row items-center justify-center bg-white px-3 md:px-10 py-2 gap-4">
              <div class="flex md:flex-none w-full md:w-1/3 items-center justify-center">
                <img src="/imgs/repeat.png" alt="" className="rounded-[30px] border-2" loading="lazy" />
              </div>
              <div class="flex-grow flex items-center justify-center">
                <div className="w-full text-lg md:text-2xl text-black bg-gray-200 py-4 shadow-lg rounded-lg p-8"> 
                  <p> {myStory[0]}</p> 
                  <br></br>
                  <p> {myStory[1]}</p> 
                </div>
              </div>
            </div>
            <div className="flex-none grid grid-cols-2 gap-6 bg-white py-4">
              <RoundButton onClick={()=>window.open('https://www.linkedin.com/in/colin-meltzer/','_blank')}>Resume</RoundButton>
              <RoundButton onClick={()=>window.location.href = 'mailto:mjcolin@gmail.com'}>Contact</RoundButton>
            </div>
            
          </div>
            
          </Card>
        </section>
       
       {/*
        <Card>
        <div className='flex items-center justify-center'>
          <h1 className="bg-blue-700 text-white text-5xl py-4 w-[80%] rounded-lg shadow-lg">What I Can Do</h1>
        </div>
        <div className='width-full bg-white px-10 py-2'>
            <div className="flex flex-col md:flex-row bg-white px-10 py-2 gap-4">
              <div class="flex flex-col md:flex-row items-center justify-center gap-2">
                <div className="w-full text-2xl text-black bg-gray-200 py-4 shadow-lg rounded-lg p-2"> 
                  Learning is my passion
                </div>
              <div class="flex-none w-full md:w-1/3">
                <img src="/imgs/repeat.png" alt="" className="rounded-[10px] border-2" loading="lazy" />
              </div>
              </div>
            </div>
        </div>
        </Card>
        */}
        <Card>
        <h1 className="bg-blue-700 text-white text-5xl py-4">Projects I've Worked On</h1>
      
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center p-2 md:p-4">
          {projectsToRender}
        </div>
        </Card>
        <Card>
        <h1 className="bg-blue-700 text-white text-5xl py-4">Learning Projects</h1>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center p-4">
          {theInProcessProjectsRender}
        </div>
        </Card>

        </div>

    </div>
  );
}

export default App;
