
export const myStory = [`Learning and creating new things have always been one of my greatest joys, shaping both my personal life and career. This passion led me to study computer science and teacher education for my undergraduate degree, and later, learning design and technology for my master's. For 14 years, I taught technology and math in a school setting, finding immense satisfaction in helping others discover the joy of learning. Watching students achieve the unimaginable, especially in digital fabrication, coding, and applied math, was truly rewarding.`,
"After 14 years in a school, I couldn’t ignore that entrepreneurial itch and my aspirations to expand my impact beyond a single school. That is why I started my own business MakeShift Edtech LLC, with the goal of making the technology learning experience for individuals accessible and human."] 

export const canDo = ['Develop Currliculum Offline and online','Code', 'Create Online Coures', 'Teach', 'Digital Fabrication Technology']

export const projects = [
  
   
   {title:'Online Learning Platform',
    description:`The MakeShift EdTech Learning Portal offers a catalog of courses, coaching, and curriculum, designed to make the technology learning experience accessible and human`,
    link:'https://learningportal.makeshiftedtech.com/maker-courses',
    target:'blank',
    type: 'modal',
    imgSrc: '/imgs/Online Courses.png',
    modalContent: `The MakeShift EdTech Learning Portal provides an in-depth and user-friendly platform for all things 3D printing. Our catalog features hands-on, challenge-based courses, tailored to accommodate various learning styles and objectives. Alongside these courses, we offer personalized human coaching, ensuring a supportive, individualized and interactive learning environment. Our curriculum is designed not just to impart technical knowledge, but also to foster creativity and innovation. The portal makes learning about this cutting-edge technology approachable and engaging for everyone, regardless of their prior experience.`,
    tags: ['instructional design','video editing', 'CAD']
    },
 
 
    {title:'Afterschool Makerspace Curriculum',
    description:`Developed an interactive, project-based after-school program focused on teaching students steam skills, creativity, digital fabrication and design thinking`,
    link:'',
    target:'blank',
    type: 'modal',
    imgSrc: '/imgs/Afterschool Makerspace Program.png',
    modalContent: `
 I designed a unique after-school program to immerse students in an interactive, project-based learning setting. Focused on STEAM (Science, Technology, Engineering, Arts, and Mathematics), the program offers a holistic educational journey. Initially, students engage in structured projects to build foundational skills, then progress to applying the design thinking process for their own innovative ideas and inventions. The program culminates in a community expo where students showcase their final designs and share their learning experiences. Many former students have gone on to start their own business or pursue degrees in STEAM fields in college`,
    tags: ['curriculum design','digital fabrication', 'teaching']
    },
 
 
    {title:'Makerspace Program and Layout Design',
    description:`
 I established a comprehensive makerspace program at the Carroll School, creating a dynamic learning environment and designing a new space equipped with tools and resources to foster creativity and hands-on learning.`,
    link:'',
    target:'blank',
    type: 'modal',
    imgSrc: '/imgs/Makerspace Program and Layout Design.png',
    modalContent: `I founded a groundbreaking program and makerspace at the Carroll School, dedicated to revolutionizing the way students engage with technology and creativity. This initiative involved designing a state-of-the-art facility, fully equipped with advanced tools and technology, from 3D printers to robotics kits, creating a hub for innovation and hands-on learning. The program's curriculum is carefully crafted to integrate STEAM (Science, Technology, Engineering, Arts, and Mathematics) principles, encouraging students to explore and apply these concepts in real-world scenarios.
 
 
 Through this initiative at the Carroll School, I successfully created a vibrant community of young makers and thinkers, equipped and inspired to tackle the challenges of the future.`,
    tags: ['curriculum design','digital fabrication', 'teaching']
    },
      {title:'Scratch Coding Curriculum',
    description:`
 A collection of scaffolded video-based challenges I designed to teach the fundamentals of coding using Scratch, used by teachers all over the world.`,
    link:'https://www.makeshiftedtech.com/scratch-challenges',
    target:'blank',
    type: 'modal',
    imgSrc: '/imgs/scratchw380.png',
    modalContent: `Learning to code can be daunting but immensely rewarding for students as they bring their ideas to life through working code. My Scratch coding challenges, still evolving, aim to guide students through various coding levels at their own pace, supported by live teachers and peers. These challenges have proven effective in mitigating the intimidation often felt by beginners and in igniting a passion for more complex projects. A few years ago, I collaborated with a graduate school peer who, after returning to India, introduced these challenges at the Global Discovery School. This led to an international coding initiative where American and Indian students paired up to learn Scratch programming. Despite time zone differences, these pairs successfully developed and presented unique projects to a larger group, enhancing their coding skills and cross-cultural collaboration`,
    tags: ['curriculum design','video editing', 'scratch coding']
    },
 
 
    {title:'Real World Math Curriculum',
    description:`A role-playing curriculum where students assume adult roles, applying learned math concepts to real-life situations like budgeting, tax calculations, scale model design, and investing.`,
    link:'',
    target:'blank',
    type: 'modal',
    imgSrc: '/imgs/realworldMath.png',
    modalContent: `
 This curriculum offers a role-playing experience, allowing students to immerse themselves as adults applying their math skills in real-world contexts. In this engaging learning environment, they tackle everyday financial scenarios, such as, creating and managing budgets, understanding credit card interest, designing scale models of homes, and calculating taxes. Students eagerly anticipate daily sessions, excited to see the unfolding of their and their peers' scenarios in this educational game `,
    tags: ['curriculum design','PBL', 'teaching']
    },
    {title:'Process Pad',
    description:'A DIY, large, multi-touch table I built and coded that allows you to leave digital artifacts on physical paper',
    link:'https://processpad.weebly.com/design-process.html',
    target:'blank',
    type: 'modal',
    imgSrc: '/imgs/processpadw380.png',
    modalContent: `We created a tangible, multi-media technology that allows students to explain their problem-solving process, which ordinarily remains hidden. Students will be able to explain and document their thinking process, to scaffold their metacognition through integration of the tangible and digital world using this tool.`,
    tags: ['java','laser cutting','diy touchscreen'],
    },
 
 
    {title:'Play Pad',
    description:'Created a way to add location-based sound to physical paper and pencil using fiducials',
    link:'https://youtu.be/ZUpclrxShxI',
    target:'blank',
    imgSrc:'/imgs/playpadw380.png',
    type: 'modal',
    modalContent: `This technology, a forerunner of the Process Pad, allows users to attach a range of sounds, from narratives to music, to specific points on paper. Utilizing computer vision techniques, particularly fiducials, it links a physical spot on the paper to a corresponding digital location on a computer screen. This feature enables users to add unique audio elements, like music to a drum drawing or stories to illustrations, enriching the interactive experience.`,
    tags: ['arduino','computer vision', 'processing','soldering']},
 
 
 
 
    {title:'Math Chef',
    description:'MathChef is an iOS app I designed and programmed to aid students in mastering fractions through an engaging activity of fulfilling pizza orders',
    link:'',
    target:'',
    type: 'modal',
    imgSrc: '/imgs/mathchefw380.png',
    modalContent: `This iPhone app is crafted to assist students in learning fraction fundamentals. By filling pizza orders, which double as fraction math problems, students grasp the concept of fractions as parts of a whole, learn to find common denominators, and practice reducing fractions for simplicity. The game's scaffolding approach tracks student performance, adapting the difficulty level of subsequent problems to suit their evolving understanding`,
    tags: ['objective-c','ios development', 'educational game design']
    },
 
 
 

    

    /*{title:'Sustainable Community PBL Curriculum', 
    description:'A Project Based Learning curriculum I developed that had students design a sustainable community',
    link:'',
    target:'',
    type: 'modal',
    modalContent: ``
    },*/
    ];

export const webProjects =[
    

   {title:'AI Connect 4',
    description:`AI Connect 4 Player, developed using the min-max algorithm. I used React.js for the frontend and Node.js for the backend`,
    link:'https://connect4.colinmeltzer.com/',
    type:'modal',
    target:'blank',
    imgSrc: '/imgs/connect4.png',
    modalContent:`In this project, I enhanced my React.js and Node.js skills. The AI uses the min-max algorithm, which projects the best next move by considering a set number of future moves. A crucial part of this algorithm is the heuristic, which evaluates the board setup's favorability for the AI. I devised a simple heuristic, calculating the difference in adjacent pieces between the AI and its opponent. The frontend manages the Connect 4 game logic, while the AI's moves are calculated by a Node.js API I developed for the backend, keeping the AI's decision-making process concealed from the player`,
    tags: ['react.js','node.js','express.js','API','javascript']},
    
   {title:'LED Animator',
    description:'This web app, developed with React.js, provides students with a visual interface to create LED animations for an interactive art installation',
    link:'https://ledanimator.netlify.app',
    target:'blank',
    type:'modal',
    imgSrc: '/imgs/ledanimator.png',
    modalContent:`The school I worked with commissioned an interactive "Dreamwall" to motivate students to pursue their dreams. This installation, featuring multiple rows of lights controlled by an Arduino, can display various patterns. To enable students to design their own patterns easily, I created an app using React.js. This app allows students to visually craft patterns, which then generates Arduino code. This code can be integrated into a base template, allowing the addition of custom patterns as new functions`,
    tags: ['react.js','arduino','javascript']},


   {title:'Toy Car Dashboard',
    description:`A laser-cut toy car dashboard, designed using OnShape CAD software, featuring interactive LEDs and powered by an Arduino`,
    link:'',
    target:'',
    type:'modal',
    imgSrc:'/imgs/dashboardw380.png',
    modalContent: `My daughters, aged 1 and 4, are fascinated with pressing buttons, especially those in cars. Inspired by this, I created a toy car dashboard that not only entertains but also educates them about color mixing. I designed the dashboard's laser-cut body using Onshape, and the 3D-printed clips and holders for the internal electronics were designed in Fusion 360. At the heart of this toy is an Arduino, which I programmed to allow the front buttons to control a Neopixel ring's colors. This feature visually demonstrates how red, green, and blue, lights combine to create new colors. Additionally, the dashboard includes two laser-cut drawers for extra storage. For safety and educational purposes, the electronics are concealed behind a panel that I can remove to show my daughters the device's inner workings`,
    tags: ['onshape','arduino','laser cutting','soldering']
   },  


   {title:'Visual Percentage Timer',
    description:`This Vue.js-based web app offers teachers a user-friendly visual timer displaying the remaining time as a percentage.`,
    link:'https://visualtimer.makeshiftedtech.com',
    target:'blank',
    type:'modal',
    imgSrc: '/imgs/visualTimer.png',
    modalContent:`Developed with Vue.js, this web app is a valuable tool for educators, featuring an easy-to-use visual timer. It displays time both elapsed and remaining as a percentage, helping teachers and students efficiently manage task durations. The timer changes color at 50% and 25% time markers, enhancing time awareness in classrooms and aiding in giving students an awareness of how much time has passed.`,
    tags: ['vue.js','javascript']
    },


   {title:'Pixel Art',
    description:`This web app, developed as a project to enhance my skills in Vue.js, allows users to create and save pixel art. It utilizes Firebase for backend functionality`,
    link:'',
    target:'blank',
    type:'modal',
    imgSrc: '/imgs/pixelart.png',
    modalContent: `This web application, which I developed as a practical project to deepen my understanding of Vue.js, serves as a platform for users to craft and store pixel art designs. Throughout the development process, I gained substantial insights into Vue.js's capabilities and nuances. Integrating Firebase as the backend solution was another learning curve. I delved into Firebase's real-time database features, which allowed for instant saving and retrieval of the pixel art creations. This integration demonstrated Vue.js’s flexibility in working with different backend technologies and gave me practical experience in full-stack development.`,
    tags: ['vue.js','firebase', 'javascript']},




    
]

export const aboutMe = [{
    title: 'My Story',
    description: `I am an educator, software developer, and maker who is passionate about using technology to create a positive impact on society. I have a background in digital fabrication, computer science, curriculum design, educational technology and teaching. I love learning new skills especially around technology and working collaboratively with others to solve interesting problems.
    `,
    link:'',
    target:'',
    type: 'noninteractive'
  },

  {
  title: 'Interests',
  description: ' I am interested in makerspaces, software development, pedagogy, education',
  link:'',
  target:''
  }

  ];
