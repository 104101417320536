import { useState } from 'react';
import Modal from './ui/Modal.js';



function Item(props){
//pre: pros are Title, Description and Link, Target
    
    const [displayModal, setDisplayModal] = useState(false);

    function link(target){
        if(props.type == null){
            //if type is null then just go to link
            switch (target){
                 case 'blank':
                      window.open(props.link,'_blank');    
                     break;
                default:
                      break;
            }
        }
        else if(props.type === "modal"){
            //display modal
            setDisplayModal(true);
        }
    }

    function goToLink(){
        switch (props.target){
            case 'blank':
                 window.open(props.link,'_blank');    
                break;
           default:
                 break;
       }
    }

    function closeModal(){
        console.log('close modal called');
        setDisplayModal(false);
    }

 
   let tags = null;
   let tagsModal = null;
   if(props.tags != null){
     console.log('got here')
     tags =  props.tags.map((item, index)=>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#{item}</span>
            )
     tagsModal =  props.tags.map((item, index)=>
            <span class="inline-block bg-blue-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">#{item}</span>
        )
    }


    //show hover state or not
    let isLink = 'hover:shadow-lg hover:ring-2 hover:ring-blue-500 cursor-pointer'
    if (props.link === '' && props.type !== 'modal')
        isLink = ''

    return (
        <div>
            <div className={`bg-white text-black max-w-sm border-2 border-color-gray-400 rounded-[20px] min-h-[600px] max-h-[600px] ${isLink}`} onClick={()=>link(props.target)}>
                <img className="w-full rounded-t-[20px] max-h-[400px]" src={props.imgSrc} alt=""></img>
                <div className="px-6 py-4">
                <div className="font-bold text-lg mb-2">{props.title}</div>
                <p className="text-gray-700 text-base">
                    {props.description}
                </p>
            </div>
            <div className="px-6 pt-4 pb-2">
                {tags}
            </div>
             
            
            </div>

       
            {displayModal && 
            <Modal close={closeModal} title={props.title} buttonClick={goToLink} imgSrc={props.imgSrc} link={props.link}>
                <p>{props.modalContent}</p>
                <div className="px-6 pt-4 pb-2">{tagsModal}</div>
            </Modal>}
          
        </div>
        
    );
}

export default Item;

