
function Roundbutton(props){

    return(
        <button className="w-50 md:w-100 bg-blue-500 text-white text-lg hover:bg-blue-300 font-bold py-2 px-4 rounded-[20px] shadow hover:shadow-md transition duration-150 ease-in-out" onClick={props.onClick}>
            {props.children}
        </button>
    );

}

export default Roundbutton;