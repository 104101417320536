
function Card(props){


    return (
        <div className="shadow-lg bg-white items-center">
           {props.children}
        </div>
    );

    
}

export default Card;